<template>
<v-app>
    <div>
        <v-alert dark dismissible v-model="alert" border="left" class="alert" :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
            {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
            <div class="mb-8 mt-8 text-center">
                <h1>Upload Timesheet</h1>
            </div>
            <v-form ref="salarycomponent" v-model="valid">
                <v-row no-gutters>
                    <v-col>
                        <SelectField :items="salaryCycles" v-model="formData.salaryCycle" :label="'Salary Cycle'" :itemText="'description'" :itemValue="'id'" :returnObject="true" @input="selectedCycle" />
                    </v-col>
                    <v-col>
                      <v-file-input
                        show-size
                        outlined
                        dense
                        accept=".xls, .xlsx"
                        label="Select a file"
                        @change="fileChange"
                      >
                      </v-file-input>
                      <!-- <input @change="fileUpload" type="file"> -->
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <Button :label="'Upload Timesheet'" :btnType="'Submit'" @onClick="uploadFile" :isLoading="creating" class="" />
                    </v-col>
                </v-row>
            </v-form>
            <!-- <p>{{personnel}}</p> -->
        </div>
    </div>

    <!-- timesheet calendar  -->

    <div v-if="timeSheets.length > 0" class="scroll row">
        <div class="col-md-11 col-sm-12 ml-auto mr-auto">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Personnel</th>
                            <th v-for="item in headers" :key="item.headerName">{{item.headerName}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="timesheet in timeSheets" :key="timesheet.employeeId">
                            <td>
                              <div class="actionBtn">
                                <v-icon color="red" small @click="removePersonnel(timesheet)">mdi-delete</v-icon>
                                <span class="ml-2">{{timesheet.name}}</span>
                              </div>
                            </td>
                            <td v-for="(item, index) in timesheet.timesheetItems" :key="item.timesheetItemDate">
                                <span v-if="timesheet.timesheetItems[index].show === true">
                                  <!-- <b>Work Hrs</b> -->
                                  <input type="Number" min="0" max="timesheet.rotationHours" value="timesheet.timesheetItems[index].rotationHours" class="hour-input" v-model="timesheet.timesheetItems[index].hoursWorked" @blur="updateWorkHour" />
                                </span>
                                <span v-if="timesheet.timesheetItems[index].show === false" class="offday">
                                  <b>{{timesheet.timesheetItems[index].type}}</b>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </div>
    <!--end timesheet calendar  -->
    <v-row v-if="timeSheets.length > 0">
        <v-col cols="11 mt-5" class="d-flex justify-end">
            <Button :label="'Submit'" :btnType="'Submit'" @onClick="dialog = true" :disabled="!valid" :isLoading="isTimesheet" class="mr-4" />

            <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
        </v-col>
    </v-row>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isTimesheet" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
</v-app>
</template>

<script>
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import Button from '@/components/ui/form/Button.vue'
import { UPLOAD_TIMESHEET } from '@/store/action-type'
import { salaryPeriodService, employeeService } from '@/services'
import moment from 'moment'
import XLXS from 'xlsx'

export default {
  components: {
    // TextField,
    SelectField,
    Button,
    ConfirmationDialog
  },
  props: ['value'],
  data () {
    return {
      dialog: false,
      selectedPersonnel: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isTimesheet: false,
      formData: {
        requestId: '',
        personnel: '',
        salaryCycle: {}
      },
      search: '',
      personnelSearch: [],
      requestType: [],
      salaryCycles: [],
      personnel: [],
      selected: [],
      headers: [],
      timeSheets: [],
      uploadedTimesheets: [],
      searching: false,
      creating: false,
      searchHeaders: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'department',
          value: 'departmentName'
        },
        {
          text: 'rotation',
          value: 'rotationName'
        }
      ],
      personnelHeaders: [
        {
          glutenfree: true,
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      timesheetFile: null
    }
  },
  methods: {
    fileChange (files) {
      this.timesheetFile = files
    },
    uploadFile () {
      let reader = new FileReader()
      const headers = this.headers
      const salaryCycleId = this.formData.salaryCycle.id
      let that = this
      reader.readAsBinaryString(this.timesheetFile)
      try {
        reader.onload = function (e) {
          let data = e.target.result
          let workbook = XLXS.read(data, { type: 'binary' })
          workbook.SheetNames.forEach(sheet => {
            let rowData = XLXS.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
            that.uploadedTimesheets = rowData

            const newTimesheets = []
            if (headers.length > 0) {
              rowData.forEach((item) => {
                const newTimesheetItems = []
                headers.forEach(header => {
                  const workHour = item[header.dateString]

                  if (!isNaN(workHour)) {
                    newTimesheetItems.push({
                      timesheetItemDate: header.date,
                      hoursWorked: workHour,
                      isRegular: true,
                      isOvertime: true,
                      isShift: true,
                      type: 'Workday',
                      show: true
                    })
                  } else {
                    newTimesheetItems.push({
                      timesheetItemDate: header.date,
                      hoursWorked: 0,
                      isRegular: true,
                      isOvertime: true,
                      isShift: true,
                      type: workHour || '',
                      show: false
                    })
                  }
                })

                const newTimesheet = {
                  prNumber: item['PR Number'],
                  name: item.Name,
                  salaryPeriodId: salaryCycleId,
                  timesheetItems: newTimesheetItems
                }

                newTimesheets.push(newTimesheet)
              })
            }

            that.timeSheets = newTimesheets
          })
        }
      } catch (error) {
        // console.error(error)
      }
    },
    fileUpload (e) {
      this.timesheetFile = e.target.files[0]
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isTimesheet = true
      this.$store.dispatch(UPLOAD_TIMESHEET, this.timeSheets, this.formData.salaryCycle.id).then((res) => {
        // console.log(res)
        this.showAlertMessage('Timesheet successfully saved', 'success')
        this.timeSheets = []
        this.personnelSearch = []
        this.personnel = []
        this.personnelHeaders = []
        this.isTimesheet = false
      }).catch(() => {
        this.showAlertMessage('Unable to save Timesheet', 'error')
        this.isTimesheet = false
      })
    },
    generateTimesheet () {
      this.creating = true
      const payload = {
        timeSheets: this.timeSheets
      }
      this.$store.dispatch(UPLOAD_TIMESHEET, payload).then((res) => {
        // const newTimesheets = []
        // const employeeTimesheets = res.data
        // employeeTimesheets.forEach(timesheet => {
        //   const newTimesheetItems = []
        //   this.headers.forEach(header => {
        //     const date = moment(header.date).format('MM-DD-YYYY')
        //     let timeSheetItem = timesheet.timesheetItems.find(item => moment(item.timesheetItemDate).format('MM-DD-YYYY') === date)
        //     if (timeSheetItem !== undefined) {
        //       if (timeSheetItem.type === 'Workday') {
        //         timeSheetItem = Object.assign({}, timeSheetItem, { show: true })
        //       } else {
        //         timeSheetItem = Object.assign({}, timeSheetItem, { show: false })
        //       }
        //       newTimesheetItems.push(timeSheetItem)
        //       console.log('generateTimesheet -> timeSheetItem', timeSheetItem)
        //     } else {
        //       newTimesheetItems.push({
        //         timesheetId: 0,
        //         timesheetItemDate: date,
        //         hoursWorked: 0,
        //         isRegular: false,
        //         isOvertime: false,
        //         isShift: false,
        //         type: 'Off day',
        //         show: false
        //       })
        //     }
        //   })

        //   if (timesheet !== undefined) {
        //     const newTimesheet = {
        //       timesheetId: timesheet.timesheetId,
        //       name: timesheet.name,
        //       employeeId: timesheet.employeeId,
        //       salaryPeriodId: this.formData.salaryCycle.id,
        //       rotationHours: timesheet.rotationHours,
        //       timesheetItems: newTimesheetItems
        //     }

        //     newTimesheets.push(newTimesheet)
        //   }
        // })

        // this.timeSheets = newTimesheets
        // this.showAlertMessage('Timesheet successfully created', 'success')
      }).catch(() => {
        // console.log(error)
        // this.showAlertMessage('Failed to submit your Timesheet', 'error')
      }).finally(() => {
        this.creating = false
      })
    },
    selectPersonnel (item) {
      this.personnel.push({
        name: item.name,
        id: item.id
      })
      this.search = ''
      // this.createTimesheetTable()
    },
    selectedCycle (salaryData) {
      let dateArr = []
      const prevDate = moment(salaryData.startDate).format('MM-DD-YYYY')
      const nextDate = moment(salaryData.endDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        dateArr.push({
          headerName: moment(start).format('ddd, MMM D'),
          date: moment(start).format('MM-DD-YYYY'),
          dateString: moment(start).format('D-MMM-yyyy')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      this.headers = dateArr
    },
    createTimesheetTable (timeSheetJson) {
      const newTimesheets = []
      if (this.headers.length > 0) {
        timeSheetJson.forEach(item => {
          const newTimesheetItems = []
          this.headers.forEach(header => {
            const workHour = item[header.dateString]

            if (!isNaN(workHour)) {
              newTimesheetItems.push({
                timesheetItemDate: header.date,
                hoursWorked: workHour,
                isRegular: true,
                isOvertime: true,
                isShift: true,
                type: 'Workday',
                show: true
              })
            } else {
              newTimesheetItems.push({
                timesheetItemDate: header.date,
                hoursWorked: 0,
                isRegular: true,
                isOvertime: true,
                isShift: true,
                type: workHour,
                show: false
              })
            }
          })

          const newTimesheet = {
            prNumber: item.PRNumber,
            name: item.EmployeeName,
            salaryPeriodId: this.formData.salaryCycle.id,
            timesheetItems: newTimesheetItems
          }

          newTimesheets.push(newTimesheet)
        })
      }
    },
    updateWorkHour (e) {
      // console.clear()
    },
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    removePersonnel (item) {
      // let index = null
      const newTimesheet = this.timeSheets.filter(timeSheet => timeSheet.prNumber !== item.prNumber)
      // const newPersonnel = this.personnelSearch.filter(person => person.id !== item.employeeId)
      this.timeSheets = newTimesheet
      // this.personnel = newPersonnel

      // for (let i = 0; i < this.timeSheets.length; i++) {
      //   if (this.timeSheets[i].emloyeeId === item.emloyeeId) {
      //     index = i
      //     break
      //   }
      // }
      // this.timeSheets.splice(index, 1)
    },
    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
      // if (this.selectedPersonnel.length > 0) {
      //   this.createTimesheetTable()
      // }
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    },
    personnelSearchFunction () {
      this.searching = true
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
        this.searching = false
      })
    }
  },
  mounted () {
    salaryPeriodService.getOpenSalaryPeriod().then(result => {
      this.salaryCycles = result.data
    }).catch(() => {
    })
  }
}
</script>

<style scoped>
  .timesheet-table {
    height: 600px;
    overflow: auto;
  }
  .holiday {
    background-color: orange;
    color: #fff;
  }

  .offday {
    background-color: maroon;
    color: #fff;
  }
  .radiogroup {
      width: 50%;
      align-items: flex-start !important;
  }

  .row .col {
      box-shadow: none !important;
      padding: 0 !important;
  }

  .alert {
      position: fixed;
      z-index: 9999;
      top: 5px;
      min-width: 50%;
  }

  .alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
  }

  .alert-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
  }

  .btn-style {
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
  }

  .text-style {
      text-transform: capitalize;
  }

  .actionBtn {
      display: flex;
      justify-content: flex-start;
  }

  .hour-input {
      border: 1px rgb(156, 154, 154) solid;
      width: 50px;
      padding: 1px;
  }

  .v-data-table__wrapper {
      overflow-y: auto !important;
      max-height: 400px !important;
  }
</style>
